<template>
  <div class="shop-all-container">
    <div class="max-w-rifyo w-full max-xl:px-5">
      <div
        class="shop-all-header-shop max-w-rifyo mx-auto px-0 w-full flex flex-col"
      >
        <h1
          v-if="isShopAll"
          class="shop-all-text w-full font-bold text-5xl mb-5"
        >
          Shop All
        </h1>
        <h1 v-else class="shop-all-text w-full font-bold text-5xl mb-5">
          {{ titlePage }}
        </h1>
        <div
          class="w-full flex max-lg:flex-col max-lg:gap-5 lg:flex-row align-center justify-between"
        >
          <div class="shop-all-shop-title">
            <span class="shop-all-text1 m-0"
              >{{
                productTotal > 0
                  ? `${productTotal} items found`
                  : '0 item found'
              }}
            </span>
          </div>
          <div
            v-if="titlePage === 'Shop All'"
            class="shop-all-filter-row lg:grid grid-cols-2 gap-4"
          >
            <input-filter
              rootClassName="col-span-1 rounded-none"
              :on-option="selectCategory.bind(this)"
              :option-data="categoriesName"
            />
            <input-filter
              rootClassName="col-span-1 rounded-none"
              textinputPlaceholder="Sort By"
              :on-option="orderByProduct.bind(this)"
              :option-data="productParams.orderData"
            />
          </div>
          <div v-else class="shop-all-filter-row lg:grid grid-cols-2 gap-4">
            <input-filter
              rootClassName="col-span-1 rounded-none"
              :on-option="selectCategory.bind(this)"
              :option-data="selectedCategory"
            />
            <input-filter
              rootClassName="col-span-1 rounded-none"
              textinputPlaceholder="Sort By"
              :on-option="orderByProduct.bind(this)"
              :option-data="productParams.orderData"
            />
          </div>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="loading flex justify-center items-center w-full"
      >
        <Loading />
      </div>
      <div v-else class="max-w-rifyo mx-auto">
        <div
          class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-16"
        >
          <div
            v-for="(product, index) in products"
            :key="'product-' + index"
            class="flex flex-grow-0"
          >
            <shop-card
              :image_src="showImage(product)"
              :title="product.title"
              :hargaDisc="calculatePrice(product)"
              :hargaNormal="normalPrice(product)"
              :item="product"
            />
          </div>
        </div>
      </div>
      <div
        class="shop-all-container-pagination mb-16 max-w-rifyo mx-auto px-0 w-full"
        v-if="!isLoading"
      >
        <app-pagination
          v-if="productTotal > 0"
          :get-page="fecthProduct.bind(this)"
          rootClassName="rootClassName"
          :selected="productParams.page"
          :total="productTotal"
          :per_page="productParams.perPage"
          :current_page="productParams.currPage"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import InputFilter from '../components/input-filter';
import ShopCard from '../components/shop-card';
import AppPagination from '../components/pagination';
import Footer from '../components/footer';
import Api from '../api';
import Loading from '../components/Loading.vue';

export default {
  name: 'ShopAll',
  components: {
    InputFilter,
    ShopCard,
    AppPagination,
    Footer,
    Loading,
  },
  data() {
    return {
      products: [],
      productTotal: 0,
      productParams: {
        page: 1,
        perPage: 12,
        currPage: 1,
        orderBy: 'latest',
        categoryId: null,
        orderData: ['latest', 'oldest', 'price_min', 'price_max'],
      },
      categories: [],
      categoriesName: [],
      selectedCategory: ['Shop All'],
      isLoading: false,
    };
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },

    isShopAll() {
      return this.$route.fullPath === '/shop/all';
    },

    titlePage: {
      get() {
        return this.$store.state.titlePage;
      },
      set(value) {
        this.$store.commit('setTitlePage', value);
      },
    },
  },
  created() {
    if (this.$route.fullPath == '/shop/all') {
      this.$store.commit('setTitlePage', 'Shop All');
      this.$store.commit('setShopAllCategory', { name: null });
    }
    this.getProduct();
    this.getCategory();
  },
  methods: {
    normalPrice(product) {
      const minPrice = Math.min(
        ...product.product_variants.map((variant) => variant.product.price)
      );
      const startPrice = product.product_variants.find(({ product }) => {
        return product.price == minPrice;
      });
      return startPrice ? startPrice.product.price : product.price;
    },
    calculatePrice(product) {
      const minPrice = Math.min(
        ...product.product_variants.map((variant) => variant.product.price)
      );
      const startPrice = product.product_variants.find(({ product }) => {
        return product.price == minPrice;
      });

      const discountType = startPrice.discount_type;
      const price = parseInt(startPrice.product.price);
      const discount = parseInt(startPrice.maximum_decimal);

      let finalPrice = 0;
      if (discountType === 'percent') {
        finalPrice = price - (price * discount) / 100;
      } else if (discountType === 'fixed') {
        finalPrice = price - discount;
      } else {
        finalPrice = price;
      }
      return finalPrice ? finalPrice : product.price;
    },
    showImage(item) {
      return item?.image?.thumbnail
        ? item.image.thumbnail
        : 'https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png';
    },
    fecthProduct(page) {
      this.productParams.page = page;
      this.getProduct();
    },
    orderByProduct(order) {
      this.productParams.orderBy = order;
      this.getProduct();
    },
    selectCategory(op) {
      if (op === 'ALL') {
        this.productParams.categoryId = null;
        // this.selectedCategory = 'Shop All';
        this.$store.commit('setTitlePage', 'Shop All');
        if (this.$route.params.slug !== 'all') {
          this.$router.push('/shop/all');
        }
        this.getProduct();
      } else {
        const item = this.categories.find((res) => res.title === op);
        this.productParams.categoryId = item.id;
        // this.selectedCategory = item.title;
        this.selectedCategory[0] = item.title;
        this.$store.commit('setTitlePage', item.title);
        this.$router.push(`/shop/${item.slug}`);
        this.getProduct();
      }
    },
    async getProduct() {
      try {
        this.isLoading = true;
        let fullUrl =
          Api.productUrl +
          `&perpage=${
            this.productParams.perPage ? this.productParams.perPage : '10'
          }&page=${
            this.productParams.page
          }&order=${this.productParams.orderBy.toLowerCase()}`;
        if (this.$route.params.slug != 'all') {
          fullUrl += `&category_slug=${this.$route.params.slug}`;
        }
        if (this.productParams.categoryId) {
          fullUrl = fullUrl + `&category_id=${this.productParams.categoryId}`;
        }
        if (this.$route.query.search) {
          fullUrl = fullUrl + `&search=${this.$route.query.search}`;
        }
        const response = await this.$http.get(fullUrl);
        this.products = response.data.data;
        this.productTotal = response.data.total;
        this.productParams.perPage = response.data.per_page;
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getCategory() {
      try {
        const response = await this.$http.get(Api.categoryUrl);
        let subcategori = [];
        response.data.data.forEach((a) => {
          a.child.forEach((b) => {
            subcategori = [...subcategori, b];
          });
        });
        this.categories = subcategori;
        this.categoriesName = subcategori.map((item) => item.title);
        this.categoriesName = ['ALL', ...this.categoriesName];
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  watch: {
    slug() {
      this.getProduct();
    },
  },
};
</script>

<style scoped>
.loading {
  margin-top: 164px;
  margin-bottom: 164px;
}
.shop-all-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.shop-all-header-shop {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: 160px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.shop-all-shop-title {
  width: 240px;
  display: flex;
  flex-direction: column;
}
.shop-all-text {
  color: #1f252c;
  font-size: 2em;
  font-family: Lora;
}
.shop-all-text1 {
  color: #575f6e;
  font-size: 18px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-family: Montserrat;
}
.shop-all-filter-row {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.shop-all-shop-list {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 56px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
}
@media (max-width: 1024px) {
  .shop-all-shop-list {
    gap: 16px;
  }
}
.shop-all-container-pagination {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  justify-content: center;
}
/* .shop-card {
  width: 23%;
} */
@media (max-width: 991px) {
  .shop-all-header-shop {
    flex-wrap: wrap;
    padding-top: 96px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .shop-all-shop-title {
    width: 100%;
  }
  .shop-all-filter-row {
    width: 100%;
    margin-top: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .shop-all-shop-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .shop-card {
    width: 100%;
  }
}
</style>
